import { PageContext } from "@util/types";
import { PageProps } from "gatsby";
import { Query } from "@graphql-types";
import React from "react";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const WorkshopTemplate = ({ data, pageContext }: Props) => {
  return <></>;
};

export default WorkshopTemplate;
